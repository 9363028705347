import * as React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { format, parseISO, endOfDay } from 'date-fns'

import { Layout, PageTitle } from '../../components/layout'
import { EventItem } from '../../components/EventItem'
import { Paragraph } from '../../components/typography'

const EventPage = () => {
    const data = useStaticQuery(graphql`
        {
            allSanityEvent(sort: { fields: date, order: ASC}) {
                edges {
                    node {
                        _id
                        title
                        slug {
                            current
                        }
                        time
                        date
                        location
                    }
                }
            }
        }
    `)

    const events = data.allSanityEvent.edges

    const seo = {
        title: `Events`,
        description: `Upcoming Events.`
    }

    const getFutureEvents = (events) => {
        // Get todays date
        const currentDate = format(endOfDay(new Date()), 'yyyyMMdd')

        // Format the date of the event
        const getEventDate = (date) => format(parseISO(date), 'yyyyMMdd')

        // Compare the array of events with the current date to return events in the future
        return events.filter(event => getEventDate(event.node.date) > currentDate)
    }

    const futureEvents = getFutureEvents(events)

    console.log(futureEvents)

    return (
        <Layout seo={seo}>
            <PageTitle title="Events" />
            <Wrapper>
                {futureEvents && futureEvents.length > 0 ?
                    futureEvents.map(event => {
                        return (
                            <EventItem input={event.node} key={event.node._id} />
                        )
                    }) :
                    <Paragraph lead textAlign='center'>No upcoming events, check back soon!</Paragraph>
                }
            </Wrapper>
        </Layout>
    )
}

export default EventPage

const Wrapper = styled.section`
    max-width: 100ch;
    margin: var(--space-06) auto;
`