import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { H4, Paragraph } from './typography'

export const EventItem = ({ input }) => {
    return (
        <Wrapper to={`/events/${input.slug.current}`}>
            <Date>
                {input.date}
            </Date>
            <Content>
                <H4 margin='0'>{input.title}</H4>
                <Paragraph margin='0'>{input.time} | {input.location}</Paragraph>
            </Content>
        </Wrapper>
    )
}

const Wrapper = styled(Link)`
    display: flex;
    gap: var(--space-06);
    flex-direction: row;
    text-decoration: none;
    padding: var(--space-03);
    transition: all 0.15s ease-in-out;

    &:hover {
        background: var(--color-primary-light-tint);
    }
`

const Date = styled.aside`
    background: var(--color-secondary-light-tint);
    color: var(--color-secondary-dark-shade);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--space-03);
`

const Content = styled.section``